<template>
	<div class="body">
		<div class="box">
			<H5Header />
			<div class="dtgk">
				<div class="bigtitle">{{name}}</div>
<!--				<div class="smalltitle">DYNAMIIC GENERALIZATION</div>-->
			</div>
			<div class="zjcx" @click="switchPage('zjcx')" v-if="false">证件查询</div>
			<div class="viewbox" >
        <div class="newsitem-title">
          {{articleDetail.title}}
        </div>
        <div class="source">
          <div class="line">
            <div class="l-1">
              来源:
            </div>
            <div class="l-2">
              {{articleDetail.source}}
            </div>
           </div>
          <div class="line">
            <div class="l-1">
              时间:
            </div>
            <div class="l-2">
              {{articleDetail.createTime}}
            </div>
          </div>
        </div>
        <div class="content">
          <div v-html="articleDetail.content || ''">

          </div>
        </div>
			</div>
			<H5Footer />
		</div>
		<div class="layer" v-show="layer==='dtgk'" @click.self="switchPage('')">
			<div class="layerbox">
				<div v-for="(item,index) in dtgk">
					<div class="layeritem">
						<a href="h5view"><div class="layeritemtitle">{{item}}</div></a>
						<div class="layermore">></div>
					</div>
					<hr class="layerline" v-if="index<6" />
				</div>
			</div>
		</div>
		<div class="zjcxlayer" v-show="layer==='zjcx'" @click.self="switchPage('')">
			<div class="layertitle">证件查询</div>
			<hr class="layerline">
			<div>
				<div v-for="(item,index) in zjcx">
					<div class="zjitem">
						<div class="zjtitle">{{item}}:</div>
						<input class="intext" placeholder="必填"/>
					</div>
				</div>
				<img class="arrow02" src="./image/arrow02.png" />
				<div class="yzm">
					<div class="yztitle">验证码：</div>
					<div class="inbox">
						<input class="inyzm" placeholder="必填"/>
						<div class="yzcode">abFG</div>
					</div>
				</div>
				<div class="cxbtn">查询</div>
			</div>
		</div>
	</div>
</template>

<script>
import H5Header from "./components/header"
import H5Footer from "./components/footer"
	export default {
    components:{
      H5Header,
      H5Footer
    },
		data() {
			return {
				layer: '',
				zjcx: ['证件号', '企业名称', '证件类别', ],
				dtgk: ['动态概括','中心概括', '中心简介', '中心领导', '发展历程', '组织机构', '办事机构', '中心资源', ],
        name:'',
        articleDetail:''
			}
		},
    mounted() {
		  this.name = this.$route.query.name || ''
      this.articleId = this.$route.query.articleId;
      //获取详情
      this.getArticleData(this.articleId)
    },
    methods: {
			switchPage(layer) {
				this.layer = layer ? layer : '';
			},
      async getArticleData(articleId){
        let res = await this.$api.getArticleData({id:articleId});
        this.articleDetail = res.data.data;
        if(this.name == null){
          this.name = res.data.data.columnName;
        }
        console.log(res.data.data)
      },
		}
	}
</script>

<style scoped >
	.body {
		width: 100%;
		overflow: scroll;
	}

	.box {
		margin: auto auto;
		background: #FFFFFF;
	}

	.header {
		/*margin: 10px auto;*/
		/*width: 320px;*/
		height: 50px;
		display: flex;
		justify-content: space-around;
	}

	.logo{
		margin-top: 10px;
		width: 250px;
		height: 33.52px;
	}
	.menu{
		margin-top: 15px;
		width: 22px;
		height: 15.5px;
	}
	.dtgk {
		/*width: 320px;*/
		height: 179px;
		background-image: url(image/dtgk.png);
		background-size: 100% 100%;
	}

	.bigtitle {
		padding-top: 65px;
		margin-left: 15px;
		font-size: 23px;
		font-family: Microsoft YaHei;
		color: #FFFFFF;
	}

	.smalltitle {
		margin-left: 15px;
		color: #FFFFFF;
		font-size: 12px;
		font-family: Microsoft YaHei;
	}

	.zjcx {

		width: 300px;
		height: 42.5px;
		line-height: 42.5px;
		text-align: center;
		background-color: #244991;
		border-radius: 50px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		color: #FFFFFF;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 10px;
	}

	.tips {
		width: 300px;
		margin: 30px auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding-bottom: 20px;
		border-bottom: #ecf5ff 2px solid;
	}

	.tipstext {
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	}
	.zxjj{
		color: #cccccc;
	}
	.tipsmenu {
		margin-left: 80px;
		width: 22px;
		height: 16px;
	}

	.hzhbbox {
		margin-top: 20px;
		/*width: 320px;*/
		background: #00337D;
		color: #FFFFFF;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.hztitle {
		margin-left: 20px;
		font-weight: 600;
		font-size: 15px;
	}
	.viewbox{
		margin: 10px;
	}
	.linebox {
		margin: 10px auto;
		width: 300px;
		height: 42.5px;
		background: #FFFFFF;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.linetext {
		margin: auto auto;
		color: #000000;
		width: 206.5px;
	}

	.arrow {
		margin: auto auto;
		width: 14px;
		height: 7px;
	}

	.qrbox {
		/*width: 320px;*/
		background: #09459B;
		padding-bottom: 20px;
		color: #FFFFFF;
	}

	.arrowtop {
		margin-left: 135px;
		width: 50px;
	}

	.zbdw {
		font-family: Microsoft YaHei;
		font-size: 15px;
		margin: 20px auto;
		width: 300px;
		border-bottom: #888888 2px dashed;
		padding-bottom: 20px;
	}

	.qrcode {
		margin: 20px 99.5px;
		width: 121px;
		height: 121px;
	}

	.kfdh {
		margin-bottom: 20px;
		font-size: 15px;
		margin-left: 10px;
	}

	.layer {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0%);
		width: 320px;
		background: #000000;
		opacity: 0.9;
		padding-bottom: 20px;
		position: absolute;
	}

	.layeritem {
		margin: 20px 10px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		color: #FFFFFF;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.layeritemtitle {
		margin-left: 5px;
		color: #FFFFFF;
	}

	.layermore {
		font-size: 18px;
		color: #2b2b2c;
	}

	.layerline {
		margin: auto auto;
		width: 300px;
		border: solid 1px #2b2b2c;
	}

	.zjcxlayer {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0%);
		width: 320px;
		background: #000000;
		opacity: 0.9;
		padding-bottom: 40px;
		padding-top: 5px;
	}

	.layertitle {
		margin-left: 20px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FFFFFF;
	}

	.layerline {
		width: 300px;
		margin: 15px auto;
		border: #244991 1px solid;
	}

	.zjitem {
		margin: 20px auto;
		width: 300px;
		height: 35px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.zjtitle {
		line-height: 35px;
		font-size: 15px;
		color: #666666;
		width: 80px;
	}

	.intext {
		width: 190px;
		border-radius: 50px;
		background-color: #F5F5F5;
		padding-left: 15px;
		padding-right: 30px;
	}

	.arrow02 {
		width: 12px;
		height: 8px;
		margin-left: 285px;
		margin-top: -100px;
	}

	.yzm {
		margin: -20px auto;
		width: 300px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.yztitle {
		line-height: 35px;
		font-size: 15px;
		color: #666666;
		width: 80px;
	}

	.inbox {
		width: 190px;
		height: 35px;
		display: flex;
		/* border: solid #FFFFFF; */
		flex-direction: row;
		justify-content: space-between;
	}

	.inyzm {
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 50px;
		width: 100px;
	}

	.yzcode {
		text-align: center;
		font-weight: 600;
		width: 60px;
		line-height: 42.5px;
		color: #2504be;
		background-color: #FFFFFF;
	}

	.cxbtn {
		margin-top: 50px;
		width: 300px;
		height: 42.5px;
		line-height: 42.5px;
		margin-left: 10px;
		text-align: center;
		background-color: #244991;
		border-radius: 50px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		color: #FFFFFF;
	}
  .newsitem-title{
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  .source{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .content{
    padding: 20px 0;
  }
  .line{
    display: flex;
    align-items: center;

  }
  .l-2{
    color: #a1a1a1;
    margin-left: 10px;
  }
  .line:last-child{
    margin-top: 10px;
  }
  /deep/ img{
    max-width: 100%;
  }
</style>
